import { Form } from '../../components/form-utils/Form';
import { Input } from '../../components/form-utils/Input';
import clientConfig from '../../client-config';
import { useForm } from 'react-hook-form';
import z from 'zod';
import { externalLoginSchema } from '../../schemas/external-login.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { useExternalLoginMutation } from '../../queries/external-login';
import { Spinner } from '../../components/spinner/Spinner';
import { usePostToken } from './use-post-token';
import { useFirebase } from 'react-redux-firebase';
import { error } from '../../utils/Notifications';
import cn from 'classnames';

export const LoginExternalNoAuth = () => {
  const isVisir = clientConfig.name === 'visir';
  const firebase = useFirebase();
  const form = useForm<z.infer<typeof externalLoginSchema>>({
    resolver: zodResolver(externalLoginSchema),
  });
  const {
    mutate,
    isSuccess,
    isPending,
    data,
    error: mutationError,
  } = useExternalLoginMutation();
  usePostToken(data?.token);
  if (isSuccess) {
    return (
      <div className={'tw-text-center tw-p-8'}>
        <Spinner />
      </div>
    );
  }
  return (
    <div
      className={
        'tw-p-2 tw-bg-[#1E1E1E] tw-min-h-[100vh] tw-mx-auto tw-text-white'
      }
    >
      <div className={'tw-max-w-[640px] tw-mx-auto'}>
        <div className={'tw-text-2xl tw-font-600 tw-text-center tw-py-4'}>Innskráning í {clientConfig.name === 'visir' ? 'TAL' : 'Pardus'}</div>
        <Form
          onSubmit={async (d) => {
            try {
              await firebase.login(d);
              mutate(d);
            } catch (err: any) {
              error('Notandanafn og lykilorð passa ekki saman.');
            }
          }}
          form={form}
          className={'tw-space-y-4 tw-mt-4'}
        >
          <Input name={'email'} label={'Netfang'} className={'tw-text-black'} />
          <Input
            name={'password'}
            label={'Lykilorð'}
            className={'tw-text-black'}
            type={'password'}
          />
          <div className={'tw-pt-2'}>
            <button
              type={'submit'}
              disabled={isPending}
              className={cn(
                'tw-bg-[#EB4889] hover:tw-bg-[#B01352] tw-transition-colors tw-rounded-md tw-font-600',
                'tw-px-6 tw-py-3',
              )}
            >
              Áfram
            </button>
          </div>
          {mutationError?.message && (
            <div className={'tw-p-4 tw-text-red-500'}>
              {mutationError.message}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};
