import { PropsWithChildren } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

export function Form<T extends FieldValues>({
  children,
  form,
  onSubmit,
  onError,
  ...rest
}: PropsWithChildren<
  Omit<JSX.IntrinsicElements['form'], 'onSubmit'> & {
    form?: UseFormReturn<T>;
    onSubmit: SubmitHandler<T>;
    onError?: SubmitErrorHandler<T>;
  }
>) {
  const defaultForm = useForm<T>();
  const computedForm = form || defaultForm;
  return (
    <FormProvider {...computedForm}>
      <form
        {...rest}
        onSubmit={computedForm.handleSubmit(onSubmit, (err) => {
          console.error(err);
          onError?.(err);
        })}
      >
        {children}
      </form>
    </FormProvider>
  );
}
