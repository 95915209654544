import { useEffect, useMemo } from 'react';

export function usePostToken(externalLoginToken?: string) {
  const allowedOriginList = useMemo(() => [
    'http://localhost:4200',
    'http://127.0.0.1:4200',
    'https://livestream-trpc.vercel.app',
    'https://trivio.is',
    'https://app.trivio.is',
  ], []);
  useEffect(() => {
    if (externalLoginToken) {
      allowedOriginList.forEach((origin) => {
        try {
          window.opener.postMessage({ externalLoginToken }, origin);
        } catch (e) {}
      });
    }
  }, [allowedOriginList, externalLoginToken]);
}
