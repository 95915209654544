import { useFormContext } from 'react-hook-form';
import { ErrorLabel } from './ErrorLabel';
import cn from 'classnames';

export const Input = ({
  name,
  label,
  className,
  ...props
}: Omit<JSX.IntrinsicElements['input'], 'name'> & {
  name: string;
  label?: string;
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const errorField = errors[name] as any;
  return (
    <div>
      {label && (
        <div>
          <label
            htmlFor={name}
            className={'tw-mb-2 tw-inline-block tw-font-600'}
          >
            {label}
          </label>
        </div>
      )}
      <input
        id={name}
        {...register(name)}
        className={cn(
          className,
          'tw-w-full tw-rounded-md tw-border tw-p-3 tw-placeholder-gray tw-outline-0',
        )}
        {...props}
      />
      {errorField && <ErrorLabel>{errorField.message}</ErrorLabel>}
    </div>
  );
};
