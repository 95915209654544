import z from 'zod';

export function zodParse<S extends z.ZodType<unknown, any>>(
  schema: S,
  data?: S['_input'],
): S['_output'] {
  return schema.parse(data);
}

export function zodSafeParse<S extends z.ZodType<unknown, any>>(
  schema: S,
  data: unknown,
): S['_output'] {
  const result = schema.safeParse(data);
  if (result.success) {
    return result.data;
  } else if (result.success === false) {
    console.error('Data parsing failed: ', result.error);
  }
  return data;
}

export const dateStringProcess = (arg: unknown) => {
  if (!arg) {
    return undefined;
  }
  if (typeof arg == 'string' || arg instanceof Date) return new Date(arg);
  return undefined;
};
export const dateStringSchema = z.preprocess(dateStringProcess, z.date());
