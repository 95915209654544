import { useMutation } from '@tanstack/react-query';
import { call } from '../utils/ApiManager';
import { zodParse } from '../utils/zod-utils';
import {
  externalLoginSchema,
  externalTokenResponseSchema,
} from '../schemas/external-login.schema';
import z from 'zod';

export const useExternalLoginMutation = () => {
  return useMutation({
    mutationFn: async (data: z.infer<typeof externalLoginSchema>) =>
      zodParse(
        externalTokenResponseSchema,
        await call('/login-external', true, null, 'POST')(data),
      ),
  });
};

export const useExternalTokenMutation = () => {
  return useMutation({
    mutationFn: async () =>
      zodParse(
        externalTokenResponseSchema,
        await call('/external-auth/get-token', false, null, 'POST')(),
      ),
  });
};
