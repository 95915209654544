import { useEffect, useState } from 'react';
import { useExternalTokenMutation } from '../../queries/external-login';
import { Spinner } from '../../components/spinner/Spinner';
import { usePostToken } from './use-post-token';
import { LoginExternalContainer } from './LoginExternal';

export const LoginExternalWithAuth = () => {
  const { error, data, mutate, isError, isSuccess } =
    useExternalTokenMutation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  usePostToken(data?.token);
  useEffect(() => {
    mutate();
  }, [mutate]);
  useEffect(() => {
    if (isSuccess) {
      window.close();
      setTimeout(() => setShowSuccessMessage(true), 1000);
    }
  }, [isSuccess]);
  return (
    <LoginExternalContainer>
      {isError ? (
        <div>
          {error?.message || 'Eitthvað fór úrskeiðis. Reyndu aftur síðar.'}
        </div>
      ) : showSuccessMessage ? (
        <div>Innskráning tókst. Þú getur núna lokað þessum glugga.</div>
      ) : (
        <Spinner />
      )}
    </LoginExternalContainer>
  );
};
